import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const Page = ({ location }) => {
  const title = 'Cut-off';

  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <p>
          שימו לב, כל מי שלא יצא מהתחנה עד זמן החיתוך שלה יאלץ להפסיק את המרוץ.
        </p>
        <p>Cut-Off עקרי:</p>
        <p> תחנת צאלים: 09:30</p>
        <p> תחנת בארי- לה מדווש: 15:00</p>
        <p> תחנת ארז: 17:30</p>
        <p>
          משתתף שיבחר להמשיך להתקדם למרות שלא עמד בזמן חיתוך, יסיר כל אחריות
          שהיא של מארגני המרוץ ומי מטעמם ויהיה פסול מלהשתתף במרוצים הבאים.
        </p>
        <p>זמני Cut- Off לכל המקצים בתחנות השונות:</p>
        <StaticImage src="../images/cut-off-times.jpeg" alt="אולטרה עוטף עזה 2022" />

      </SectionContent>
    </Layout>
  );
};

export default Page;
